.sideBarComponent {
  position: fixed;
  left: 0;
  bottom: 0;
  height: calc(100vh - 108px);
  width: 278px;
  padding: 0 10px;
  background: #fff;
  border-right: 1px solid #ccc;
  z-index: 6;

  .content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    padding: 30px 0 24px 0;
    height: 100%;
    align-content: space-between;

    .grow {
      width: 100%;
      justify-items: flex-start;

      .sideBarDetailsComponent {
        width: 100%;

        .projectId {
          width: 100%;
          overflow-wrap: break-word;
        }
      }
    }

    .double-button {
      width: 100%;
      margin-bottom: 3rem;
    }

    .download {
      margin-top: 30px;
    }

    button {
      margin: 10px 0;
    }
  }
}

.upload {
  margin-top: 30px;

  input {
    position: absolute;
    top: -5px;
    right: -10px;
    left: -9px;
    width: 400px;
    opacity: 0;
    bottom: -6px;
  }
}

ol {
  padding: 0;
  list-style-position: inside;

  li {
    margin-bottom: 15px;
  }
}

p.margin {
  padding-top: 20px;
}

.progress {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  position: relative;

  &:after {
    position: absolute;
    left: 20px;
    right: 20px;
    height: 1px;
    top: 10px;
    background: #000;
    content: ' ';
  }

  .item {
    display: flex;
    gap: 10px;
    justify-content: center;
    flex-direction: column;

    span {
      border: 1px solid #000;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      margin: 0 auto;
      background: #fff;
      z-index: 2;
    }

    &.active {
      span {
        background: #000;
      }
    }

    p {
      margin: 0;
    }
  }
}

.md-dialog-content {
  p {
    max-width: 600px;
  }
}

.buttons-modal-holder {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .md-checkbox {
    margin: 0;
  }

  .button-yellow-full {
    width: auto;

    &:disabled {
      opacity: 0.3;
    }
  }
}