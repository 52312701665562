.adminBarComponent {
  width: 100%;
  display: flex;
  color: black;
  align-items: center;
  justify-content: space-between;
  margin: 0;

  .md-title {
    display: flex;
    flex-grow: 1;
    letter-spacing: .02em;
    font-weight: 400;
  }
}