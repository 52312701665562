@font-face {
  font-family: 'Avenir Next LT Pro';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro'),
    url('./fonts/AvenirNextLTPro-Regular.woff') format('woff');
}

*,
:after,
:before {
  box-sizing: inherit;
}

html {
  height: 100%;
  box-sizing: border-box;
  transition: background-color .3s cubic-bezier(.25, .8, .25, 1)
}

body {
  font-size: 14px;
  background-color: white;
  padding: 0;
  margin: 0;
}

.message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

p,
li {
  color: #333;
}

body {
  min-height: auto;
  font-family: 'Avenir Next LT Pro';
}

#app-holder {
  padding-top: 60px;
}

.md-app {
  height: 100vh;
}

.md-title {
  text-align: left;
  font-size: 30px;
  margin: 0 !important;
  line-height: 40px;
}

.js-plotly-plot .plotly .cursor-crosshair {
  cursor: default !important;
}

#app {
  font-family: 'Avenir Next LT Pro', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  z-index: 2;
  position: relative;
}

input {
  font-family: 'Avenir Next LT Pro', Helvetica, Arial, sans-serif;
}

.md-card-header:first-child>.md-title:first-child {
  margin-top: 0;
}

.md-overlay {
  bottom: inherit;
  height: 100vh;
}

.md-button-content {
  z-index: 10 !important;
  display: flex;
  align-items: center;
}

.md-ripple {
  mask-image: none;
  -webkit-mask-image: none !important;
  position: absolute;
  bottom: 10px;
  right: 0px;
}

.md-card {
  .md-title {
    font-size: 20px;
    display: flex;

    span {
      flex-grow: 1;
    }
  }
}

.col-title {
  span {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F0AE0B;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    margin: 0 6px;
    border-radius: 4px;
    position: relative;
    z-index: 4;
  }

  &:after {
    position: absolute;
    height: 100%;
    left: 6px;
    right: 6px;
    top: 0;
    background: rgba(0, 0, 0, 0.09);
    content: ' ';
    z-index: -1;
  }
}

.vue-recycle-scroller__item-view:hover {
  position: relative;
  z-index: 2;
}

.md-card-header {
  padding: 8px 16px;

  button {
    cursor: pointer;
    border: 0;
    padding: 0;
    background: none;
  }
}

.button-yellow-hollow {
  background: none;
  width: 100%;
  margin: 0;
  border: 1px solid #F0AE0B;
  color: #F0AE0B;
  transition: all 0.3s;
  cursor: pointer;

  .md-icon-font {
    color: #F0AE0B !important;
  }

  &:hover {
    background: #F0AE0B !important;
    color: #fff !important;

    .md-icon-font {
      color: #fff !important;
    }
  }

  .md-icon {
    margin-right: 10px;
  }
}

.button-yellow-full {
  background: #EFAF0A !important;
  width: 100%;
  margin: 0;
  border: 1px solid #EFAF0A;
  color: #fff !important;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background: #F0AE0B !important;
    color: #fff !important;
  }

  .md-icon {
    margin-right: 10px;
    color: #fff !important;
  }

  &:disabled {
    border: 1px solid rgba(0, 0, 0, .4);
    background: rgba(0, 0, 0, .4) !important;
  }
}

.button-green-full {
  background: #1D5D63 !important;
  width: 100%;
  margin: 0;
  border: 1px solid #1D5D63;
  color: #fff !important;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background: #1D5D63 !important;
    color: #fff !important;
  }
}

.card-filter {
  margin: 6px;
  position: relative;
  z-index: 2;
  max-width: 15vw;

  .md-card-content {
    padding: 0;

    ul {
      display: block;
      margin: 0;
      padding: 0;
      overflow-x: hidden;
      max-height: 350px;

      li {
        line-height: 50px;
        padding: 0 15px;
        text-align: left;
        white-space: nowrap;
        display: block;
        border-top: 1px solid rgba(185, 112, 112, 0.1);
        cursor: pointer;
        transition: all 0.3s;

        &.active,
        &:hover {
          background: rgba(0, 0, 0, .4);
          color: #fff;
        }
      }
    }
  }
}

.dot-flashing-holder {
  width: 100%;
  margin: 80px 0;
  display: flex;
  justify-content: center;
}

.card-indicator.md-card {
  border-bottom: 1px solid #ccc;
  margin: 6px;
  position: relative;
  z-index: 2;

  .md-title {
    font-size: 24px;
    font-weight: bold;
    display: flex;

    span {
      flex-grow: 1;
      width: 100%;
    }

    a {
      width: auto;
      height: 26px;
      font-size: 14px;
    }
  }

  .md-card-content {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    width: 100%;
    padding: 0;

    .half {
      width: 50%;
      padding: 16px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      &:last-child {
        border-left: 1px solid #ccc;
      }
    }

    .full {
      width: 100%;
      display: flex;
    }

    p {
      width: 100%;
      text-align: left;
      display: flex;
      align-items: center;

      &.center {
        justify-content: center;
        text-align: center;
        display: block;
        padding: 10px 16px;
      }

      &.big {
        flex-wrap: wrap;
        text-align: center;

        span {
          width: 100%;

        }

        strong {
          font-size: 32px;
          width: 100%;
          text-align: center;
        }
      }

      span {
        width: 70%;
      }

      strong {
        width: 30%;
        text-align: right;

        &.big {
          font-size: 34px;
          width: auto;
          margin-right: 10px;
        }
      }
    }
  }
}

.modebar {
  display: none !important;
}

.md-card.card-lines {
  margin: 6px;
  position: relative;
  z-index: 2;

  .md-title {
    padding: 0;
    font-size: 18px;
    font-weight: bold;
  }

  .md-card-content {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    padding: 0;

    p {
      width: 100%;
      text-align: left;
      display: flex;
      align-items: center;
      margin: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      padding: 10px 15px;

      &.full {
        display: block;

        span {
          display: block;
        }
      }

      span {
        width: 70%;
      }

      strong {
        width: 50%;
        text-align: right;
      }
    }
  }
}

.card-enterprise.md-card {
  margin: 6px;
  position: relative;
  z-index: 2;
  min-height: 340px;

  &.overtime {
    min-height: 340px;
  }

  .md-card-content {
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;

    .chart {
      width: 100%;
      overflow-x: auto;
    }

    p {
      width: 100%;
      text-align: left;
      display: flex;
      margin: 5px 0;
      align-items: center;

      span {
        width: 70%;
      }

      strong {
        width: 30%;
        text-align: right;
      }
    }
  }
}

.card-chart.md-card {
  margin: 6px;
  position: relative;
  z-index: 2;

  .md-card-content {
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    padding: 10px 15px;

    .numbers {
      width: 300px;
    }

    .chart {
      width: calc(100% - 330px);
      margin-left: 30px;
      min-height: 270px;
    }

    p {
      text-align: left;
      display: flex;
      margin: 10px 0;
      align-items: center;

      span {
        width: 70%;
      }

      strong {
        text-align: right;
        flex-grow: 1;
      }
    }
  }
}

.project-update {
  p {
    padding-top: 40px;
  }

  .md-layout-item {
    margin: 0 10px;
  }
}

#home {
  width: 100vw;
  background: rgba(0, 0, 0, 0.6);

  .overlay {
    background: url(assets/20150729-Annual-Reports.jpg) center;
    background-size: cover;
    opacity: 0.6;
    filter: blur(4px);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
  }

  aside {
    z-index: 3;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    background: #fff;
    max-width: 350px;
    padding: 20px 25px;
    flex-wrap: wrap;
    display: flex;
    align-content: space-between;

    img {
      height: auto;
      max-width: 70%;
      margin: 0 auto;
      display: block;
    }

    hr {
      margin: 40px 0;
    }
  }
}

.loading {
  position: relative;
  clear: left;

  .holder {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -5px 0 0 -15px;
  }
}

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;

  &::before,
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
  }

  &::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }
}

@keyframes dotFlashing {
  0% {
    background-color: #9880ff;
  }

  50%,
  100% {
    background-color: #ebe6ff;
  }
}