header {
  display: flex;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 8;
  flex-wrap: wrap;

  .title {
    color: #1D5D63;
    font-size: 20px;
    flex-grow: 1;
    line-height: 48px;
    padding-left: 20px;
  }

  .top {
    width: 100%;
    display: flex;
    padding: 0 16px;
  }

  .green-bar {
    width: 100%;
    height: 20px;
    left: 0;
    right: 0;
    background: #1D5D63;
    color: #fff;
    font-size: 18px;
    text-align: center;

    a {
      position: absolute;
      left: 15px;
      top: 2px;
      color: #fff;
      margin: 0;

      .md-icon {
        color: #fff;
      }
    }
  }

  .submenu {
    width: 100%;
    display: flex;
    padding: 0;
    margin: 0;
    height: 30px;
    align-items: center;
    margin-left: 162px;

    &.management {
      margin-left: 330px;
    }

    li {
      display: flex;
      height: 100%;
      text-align: center;
      align-items: center;

      a {
        height: 30px;
        align-items: center;
        display: flex;
        justify-content: center;
        font-size: 14px;
        text-transform: initial;
        color: #1D5D63 !important;
        border-radius: 0;
        margin: 0;

        &:hover {
          background: rgba(0, 0, 0, 0.09);
          text-decoration: none;
        }

        &.active {
          border-bottom: 2px solid #1D5D63;
        }
      }
    }
  }

  menu {
    width: 100%;
    display: flex;
    padding: 0;
    margin: 0;
    height: 40px;
    align-items: center;
    background: RGBA(29, 93, 99, 0.4);

    li {
      display: flex;
      height: 100%;
      text-align: center;
      align-items: center;

      a {
        height: 40px;
        align-items: center;
        display: flex;
        justify-content: center;
        padding-bottom: 2px;
        font-size: 16px;
        text-transform: initial;
        color: #fff !important;
        border-radius: 0;

        &:hover {
          background: rgba(0, 0, 0, 0.09);
          text-decoration: none;
        }

        &.active {
          background: #fff;
          color: #1D5D63 !important;
        }

        &.inactive {
          opacity: 0.3;
        }
      }
    }
  }

  span {
    margin: 0;
    padding: 0;
  }

  .md-title {
    img {
      height: 40px;
    }
  }
  .options {
    position: absolute;
    left: 12px;
    display: none;
    top: 0;
    padding-top: 36px;
    min-width: 150px;
    .content {
      background: #fff;
      box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
      display: flex;
      flex-wrap: wrap;
      .option {
        &:hover {
          background: #ddd;
        }
        width: 100%;
        padding: 0 0 0 10px;
        display: flex;
        button {
          min-width: inherit;
          padding: 0;
          &:first-child {
            flex-grow: 1;
          }
          &.edit,
          &.delete {
            min-width: 20%;
            text-align: center;
          }
        }
      }
      button {
        color: #2c3e50;
        padding: 0 10px;
        line-height: 40px;
        background: none;
        margin: 0;
        min-width: 180px;
        text-align: left;
        border: 0;
        cursor: pointer;
        &:hover {
          background: #ddd;
        }
      }
    }
  }
  .spacer {
    border-left: 1px solid #ccc;
    margin: 6px 10px;
    height: 36px;
  }
  .utils {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    flex-grow: 1;
    .currency {
      position: relative;
      display: flex;
      align-items: flex-end;
      span {
        margin: 0;
        padding: 14px 0 0;
      }
      label {
        position: absolute;
        top: -6px;
        left: 20px;
        font-size: 12px;
      }
      &:hover .options {
        display: block;
      }
    }
  }
  .md-drawer {
    height: 100vh;
    bottom: inherit;
    .md-icon {
      color: rgba(0, 0, 0, 0.87) !important;
    }
  }
}
