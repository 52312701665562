.md-field {
    margin: 0;
}

.card-filter {
    margin: 0;
    max-width: 100%;

    .md-card-content {
        .md-field {
            padding: 0;
            margin: 0;

            input {
                padding: 0 16px;
                min-height: 48px;
            }
        }

        ul {
            max-height: 150px;
            overflow: auto;

            li {
                cursor: pointer;
            }
        }
    }
}

.button-yellow-hollow.margin {
    margin-top: 20px;
}

.marginb {
    margin-bottom: 20px;
}

.md-autocomplete {
    flex-wrap: wrap;

    .md-menu {
        border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
    }

    &:after {
        display: none;
    }

    .md-helper-text {
        font-size: 16px;
        position: static;
        height: auto;

        strong {
            display: block;
            margin: 15px 0 10px;
        }

        .list {
            display: flex;
            flex-wrap: wrap;
            max-height: 120px;
            overflow: auto;

            span {
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: space-between;

                i {
                    font-style: normal;
                }

                button {
                    min-width: auto;
                    margin: 0 0 0 10px;
                }
            }
        }
    }
}