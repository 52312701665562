.text {
  background-color: #A4BEC0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: azure;

  h1 {
    font-size: 10rem;
  }

  h2 {
    font-size: 3rem;
  }
}