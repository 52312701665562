.holder {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  padding: 20px 20px;
  height: 130px;
  position: fixed;
  bottom: 0;
  left: 277px;
  right: 0;

  .quality {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    justify-content: flex-start;

    strong {
      font-size: 50px;

      &.orange {
        color: #ff5959;
      }

      &.yellow {
        color: #facf5a;
      }

      &.green {
        color: #49beb7;
      }

      sup {
        font-size: 20px;
      }
    }

    span {
      text-align: left;
      display: flex;
      width: 100%;
      color: #800080;

      p {
        color: #800080;
        max-width: 300px;
      }
    }

    button,
    a {
      height: 28px;
    }
  }

  .final {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }

  button,
  a {
    width: 200px;
    margin: 0 0 10px;
  }
}