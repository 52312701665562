#muiItemsContainer {
  display: flex;
  width: 97%;
  flex-direction: column;
  margin: 0;
}

.breadCrumbItem {
  text-decoration: "none";
  color: 'black';
  font-size: '14px';
}

.tableTitle {
  font-weight: 400;
  font-size: 21px;
}