.overflow {
  max-height: 44vh;
  overflow: auto;
}
main {
  margin: 108px 0 130px 276px;
  display: flex;

  .center-card-error {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 238px);
    margin: 0 15px;
  }
  article {
    width: 100%;
  }

  .errorColor {
    color: #800080;
  }

  .grid {
    flex-grow: 1;
    display: flex;
    min-height: 100%;
    margin-bottom: 150px;

    &.center {
      align-items: center;
      justify-content: center;
      height: calc(100vh - 258px);
      min-height: inherit;
      align-items: center;
    }
  }

  .text-main {
    margin: 0;
    flex-wrap: wrap;

    h2,
    p {
      text-align: center;
      width: 100%;
    }

    a {
      margin: 0;
      padding: 0;
    }
  }
}
