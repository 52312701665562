.feedBackContainer {
  mask-image: none;
  -webkit-mask-image: none !important;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 10 !important;

  .feedBackIcon {
    width: 30px;
    height: 30px;
    cursor: pointer;
    color: white;
    background-color: #B69190;
    border-radius: 50%;
    margin-top: 30px;
    transition: all 0.3s;

    &:hover {
      background-color: #ff5252;
    }
  }
}