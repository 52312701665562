.disclaimer {
  max-width: 800px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 98vh;
  letter-spacing: .01em;
  font-size: 14px;

  #overlay {
    background: url(../assets/20150729-Annual-Reports.jpg) center;
    background-size: cover;
    opacity: 0.4;
    filter: blur(4px);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
  }

  .md-title {
    display: flex;
    justify-content: center;
    font-size: 20px;
    line-height: 32px;

  }

  h3 {
    margin: 20px 0 0;
  }

  p {
    text-align: left;
  }

  .cardContainer {
    background-color: white;
    z-index: 1;
    position:relative;
  }
}