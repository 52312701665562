.search-input {
  position: fixed;
  top: 72px;
  right: 15px;
  background: none;
  border: 0;
  border-bottom: 1px solid #1D5D63;
  color: #fff;
  line-height: 30px;
  height: 30px;
  width: 250px;
  z-index: 10;
  outline: none;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
}

.overflow {
  overflow: auto;
  height: calc(100vh - 130px);
  width: 100%;
}

.add {
  position: fixed;
  right: 6px;
  bottom: 136px;
  background: #1D5D63;
  padding: 0;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  padding: 0;
  min-width: auto;
  box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.6);
  transition: all 0.3s;

  span {
    opacity: 0;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    bottom: inherit;
    right: 63px;
    z-index: 10;
    color: #fff;
    line-height: 28px;
    padding: 0 10px;
    border-radius: 4px;
    text-transform: initial;
    transition: all 0.3s;
  }

  &:hover {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.4);

    span {
      opacity: 1;
    }
  }

  &:before {
    background: none !important;
  }

  .md-icon {
    color: #fff !important;
  }
}

.hide {
  display: none;
}

.toggle-lock {
  .toggle {
    position: fixed !important;
    left: 240px !important;
    z-index: 4;
  }
}

.cell-lock {
  .fixed {
    display: block;
  }
}

.fixed {
  position: fixed;
  left: 240px;
  top: 124px;
  width: 180px;
  z-index: 3;
  display: none;

  .sub-header {
    margin-top: 52px;
    border-top: 1px solid rgba(0, 0, 0, 0.4);

    .title {
      border-right: 1px solid rgba(0, 0, 0, 0.4) !important;
    }
  }

  .cell-holder {
    border-right: 1px solid rgba(0, 0, 0, 0.4) !important;
  }
}

.holder-grid {
  padding: 15px 15px 100px;

  .sub-header {
    position: relative;

    .flex {
      height: 53px;
    }

    &.info {
      &:hover {
        .tooltip {
          display: block;
          color: white;
        }
      }

      .icon {
        position: absolute;
        right: 6px;
        top: 13px;
      }

      .tooltip {
        background: rgba(0, 0, 0, 0.8);
        display: none;
        position: absolute;
        top: 60px;
        width: 300px;
        left: 50%;
        margin-left: -150px;
        z-index: 5;
        white-space: break-spaces;

        p {
          margin: 5px 6px;
          color: #fff;
        }
      }
    }
  }

  .toggle {
    position: absolute;
    background: #52B998;
    left: 16px;
    padding-left: 15px;
    width: 180px;
    color: #fff;
    display: flex;
    align-items: center;
    height: 52px;
    z-index: 5;

    .md-switch {
      margin: 0;
    }
  }

  .border {
    border-top: 1px solid rgba(0, 0, 0, .4);
    border-right: 1px solid rgba(0, 0, 0, .4);
  }

  .row {
    display: flex;
    position: relative;

    &.content {
      height: 24px;
    }

    &.non-selected {
      opacity: 0.6;
    }

    .md-checkbox {
      margin: 0;
      position: absolute;
      left: -36px;

      .md-checkbox-container:before {
        height: 20px !important;
        width: 20px !important;
      }

      .md-ripple {
        height: 20px !important;
        width: 20px !important;
      }
    }
  }

  .title {
    padding: 6px 6px;
    background: #C4E9DC;
    font-size: 12px;
    display: flex;
    border: 1px solid rgba(0, 0, 0, .4);
    border-width: 0 0 1px 1px;
    min-width: 180px;
    max-width: 180px;
    height: 53px;
    align-items: center;
    justify-content: center;
    position: relative;

    &.dark-green {
      background: #52B998;
      color: #fff;
    }

    &.dark-grey {
      background: rgba(0, 0, 0, .2);
      color: #fff;
    }

    &:first-child {
      border-left: 1px solid rgba(0, 0, 0, .4)
    }

    &.info {
      &:hover {
        .tooltip {
          display: block;
        }
      }

      .icon {
        position: absolute;
        right: 6px;
        top: 13px;
      }

      .tooltip {
        background: rgba(0, 0, 0, 0.8);
        display: none;
        position: absolute;
        top: 60px;
        width: 300px;
        left: 50%;
        margin-left: -150px;
        z-index: 5;
        white-space: break-spaces;

        p {
          margin: 5px 6px;
          color: #fff;
        }
      }
    }
  }

  .flex {
    display: flex;
    height: 100%;
  }

  .cell-holder {
    position: relative;
    display: flex;
    height: 100%;
    height: 24px;
    width: 180px;
    border: 0;
    border: 1px solid rgba(0, 0, 0, .4);
    border-width: 0 0 1px 1px;

    div.cell {
      width: 100%;
      line-height: 24px;
      text-overflow: ellipsis;
      background: #fff;
      display: flex;

      .clear {
        right: 24px;
      }

      &.purple {
        background: #800080;

        select,
        input {
          color: #fff;
        }
      }

      &.invalid {
        background: #FBCBCC;

        input {
          background: none !important;
        }
      }

      &.out-scope {
        background: #C8C8C8;
      }

      &.valid {
        background: #CFFCCF;

        input {
          background: none !important;
        }
      }

      &.warning {
        background: #FAF7D4;

        input {
          background: none !important;
        }
      }

      .tooltip {
        display: none;
        position: absolute;
        top: 30px;
        width: 300px;
        left: 50%;
        margin-left: -150px;
        background: rgba(0, 0, 0, 0.8);
        color: #fff;
        z-index: 3;
        padding: 6px 10px;
        white-space: break-spaces;
      }

      &:hover {
        .tooltip {
          display: block;
        }
      }

      button {
        padding: 0;
        border: 0;
        background: none;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        cursor: pointer;
        background: #fff;
      }

      select,
      input {
        border: 0;
        width: 100%;
        line-height: 18px;
        background: none;
        padding: 0 6px;
        height: 24px;
      }

      ul {
        position: absolute;
        top: 20px;
        left: 0;
        right: 0;
        background: #fff;
        margin: 0;
        padding: 0;
        z-index: 10;

        li {
          line-height: 18px;
          padding: 0 15px;
          font-size: 12px;
          display: block;
          cursor: pointer;

          &:hover,
          &.highlight {
            background: rgba(0, 0, 0, .1);
          }
        }
      }
    }
  }

  tr:nth-child(even) {
    td {
      background: rgba(0, 0, 0, .1);

      &.invalid {
        background: rgba(255, 0, 0, .3);
      }

      &.valid {
        background: rgba(0, 255, 0, .3);
      }
    }
  }
}