.hide {
  display: none;
}

.pointer {
  cursor: pointer;
}

.center {
  text-align: center;
}

.md-card {
  &.active {
    z-index: 2;
  }
}

.border {
  .md-card-header {
    border-top: 1px solid #ccc;
  }
}

.md-card-header,
.md-card-content {
  border-bottom: 1px solid #ccc;
  padding-left: 24px;
  padding-right: 24px;
  margin-left: -24px;
  margin-right: -24px;
}

.md-card-header {
  padding-right: 48px;
  position: relative;

  &.no-border {
    border-color: transparent;
  }

  .md-icon {
    position: absolute;
    right: 12px;
    top: 34px;
  }
}

.button-holder {
  max-width: 150px;
  margin: 0 auto;
}

.md-card-content {
  .options {
    position: absolute;
    left: 0;
    right: 0;
    top: 48px;
    margin: 0;
    padding: 0;
    background: #fff;
    z-index: 20;
    box-shadow: 3px 4px 6px rgba(0, 0, 0, 0.3);
    max-height: 150px;
    overflow: auto;

    li {
      line-height: 32px;
      padding: 0 10px;
      font-size: 16px;
      display: block;

      &:hover {
        background: rgba(0, 0, 0, 0.04);
        cursor: pointer;
      }
    }
  }
}