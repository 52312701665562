#admin {
  background-color: white;
  margin: 80px 0 30px 180px;

  .adminGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .adminSideBar {
      width: 200px;
    }

    .adminHeader {
      width: 100%;
    }
  }


}