.loginComponent{
    .title{
        color:#2c3e50;
        font-size: 21px;
    }
}
.notificationBox{
    font-size: 14px;
    color:rgb(252, 52, 52);
}

